<template>
  <div>
    <p class="pl-3 text-lg font-bold sm:text-white"><span class="typcn typcn-edit"></span> Transaksi</p>
    <div v-if="modalbayar" class="row" style="position:fixed;top:60px;right:10px;z-index:1000;width:100vw;">
        <div class="col-sm-9 order-2 sm:order-1" @click="modalbayar=false" style="height:100vh;"></div>
        <div class="col-sm-3 order-1 sm:order-2">
            <div class="bg-white rounded-lg p-3 shadow animate__animated animate__bounceInDown">
              <span class="font-semibold text-lg cursor-pointer float-right" @click="modalbayar=false">x</span>
                <div class="sm-form ">
                    <label for="nota">Nota</label>
                    <input type="text" disabled id="nota" name="nota" class="form-control form-control-sm" placeholder="nota" v-model="datawhistlist2.nota" >
                </div>
                <div class="sm-form ">
                    <label for="uang">Uang : </label>
                    <input type="number" id="uang" name="uang" class="form-control form-control-sm" placeholder="uang" v-model="uang" >
                </div>
                <div class="sm-form">
                <textarea
                type="text"
                id="keterangan"
                name="keterangan"
                rows="2"
                placeholder="keterangan..."
                class="form-control md-textarea"
                v-model="datawhistlist2.keterangan"
                ></textarea>
                </div>
                <hr>
                <p class="">total Rp. {{total}}</p>
                <p>Uang : {{uang}}</p>
                <!-- <button type="button" @click="diskon" :disabled="diskons" class="btn btn-sm btn-primary  ">Apply Diskon</button> -->
                <p v-show="uang-total">Kembalian : {{uang-total}}</p>
                <hr>
                <button type="button" @click="bayar" class="btn btn-sm btn-dark  "><span class="typcn typcn-export"></span> Bayar</button>
            </div>
        </div>
    </div>
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="  p-2 order-2 sm:order-1 " :class="`${datanya.length>0?'col-sm-8':'col-11'}`" style="font-size:12px;">
          <div class="row bg-white rounded-lg p-2">

          <div class="col-6">
            <div class="sm-form ">
                <input type="text" id="search" name="search" class="form-control form-control-sm" placeholder="search" v-model="search" >
            </div>
          </div>
          <div class="col-6">
             <select class='form-control' v-model="datawhistlist2.id_pelanggan" @change="cekmembership">
                <option v-for="(item, index) in pelanggans" :key="index+'pridils'" :value="item.id">{{item.nama}}</option>
                </select>
            <tr v-if="datawhistlist2.id_pelanggan">        
              <td class="capitalize">Membership</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                <span class="font-bold">
                  {{membership==0?'Bukan Membership':'Membership'}}
                </span>
                <span class="font-bold" v-if="membership==1">
                  <button type="button" class="btn btn-sm   " :class="`${diskonkan?'btn-primary':'btn-outline-dark'}`" @click="diskonkan=true">
                    {{diskon_membership}} %
                  </button>
                </span>
              </td>
              <td class="capitalize">Total Beli Pelanggan</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                <span class="font-bold">
                  Rp. {{total_beli_pelanggan}}
                </span>
              </td>
          </tr>
          </div>
         <div class="col-6 col-sm-4 col-md-3 col-lg-3 p-2" v-for="(item, index) in td" :key="index+'item'">
            <div class="shadow rounded-lg ml-1 cursor-pointer hover:text-orange-400 anim" style="height:100%;" >
                <img :src="item.gambar1" class="img-fluid" style="height:100px;width:100%;" alt="">
                <div class="p-3 " style="position:relative;" :class="`${item.stok_akhir=='0'?'text-gray-600':''}`">
                    <!-- <p class="text-orange-600 font-bold" v-if="item.baru">Produk</p> -->
                    <p class="capitalize">{{item.nama_produk}}</p>
                    <div class="text-sm font-bold" v-if="item.diskon=='0'">{{formatRupiah(item.harga,'Rp. ')}}</div>
                    <div class="" style="font-size:8px;" v-else>
                      <span class=" btn btn-sm btn-warning float-right " style="font-size:8px;">{{item.diskon}}%</span>
                      <span style="text-decoration: line-through;" class="">{{formatRupiah(item.harga,'Rp. ')}}</span>
                      <p>
                      <span class="font-bold" style="font-size:12px;">{{formatRupiah(item.after_diskon,'Rp. ')}}</span>
                      </p>
                    </div>
                    <div class="text-right">
                      <button type="button" @click="kurangjumlah(index,item.stok_akhir)" class="btn btn-sm btn-dark ">-</button>
                      <button type="button" @click="tambahjumlah(index,item.stok_akhir)" class="btn btn-sm btn-dark ml-1">+</button>
                      <button type="button" @click="tambahkeranjang(item,index)" class="btn btn-sm btn-success ml-1"><span class="typcn typcn-arrow-right"></span></button>

                    </div>
                    <div class="sm-form">
                        <input type="number" id="jumlah" name="jumlah"  class="form-control form-control-sm" placeholder="jumlah" v-model="jumlah[index].jumlah" >
                    </div>
                    <p class="text-xs font-semibold text-red-400">
                       Stok {{item.stok_akhir}}
                    </p>
                    <br>
                </div>
            </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        </div>
        <div v-if="datanya.length>0" class=" col-sm-4 p-2 order-1 sm:order-2" style="font-size:12px;">
          <div class="shadow bg-white rounded-lg p-2" style="min-height:30vh;">
            <div  class="">
            <button type="button" @click="modalbayar=true" class="btn btn-sm btn-style9 mr-3  waves-effect float-right text-lg"><span class="typcn typcn-export"></span> Bayar</button>
              <button type="button" @click="deleteAll" class="btn btn-sm btn-dark ml-2 "><span class="typcn typcn-eject"></span> Delete All</button>
            <br>
            <br>
            <p class="font-bold">Nota Transaksi : {{datawhistlist2.nota}}</p>
            <div>
              <table class="table table-sm table-striped table-bordered">
                  <tr v-for="(item, index) in datanya" :key="index">
                    <td>
                      <img v-viewer :src="item.gambar1" style="width:40px;height:40px;" alt="">
                    </td>
                    <td class="font-semibold">
                      <p class="text-xs">{{item.nama_produk}}</p>
                      {{formatRupiah(item.harga,'Rp. ')}} / {{item.diskon}}% / * {{item.jumlah}}
                     </td>
                    <td class="font-semibold">
                      {{formatRupiah(item.total,'Rp. ')}}
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger" @click="hapus(item)">x</button>
                    </td>
                  </tr>
              </table>
            </div>
            </div>
          </div>
        </div>
       
      </div>
    </form>
    <!-- TABLE PART -->
    <!-- END TABLE PART -->
     <div class="d-block d-sm-none"><br><br><br><br></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
import _ from 'lodash'
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      modalbayar:false,
      diskonkan:false,
      datawhistlist2: {
          jumlah:0,
      },
      jumlah:[],
      produks:[],
      datanya2:[],
      pelanggans:[],
      btn: "tambah",
      search:'',
      stok:0,
      datanya:[],
      total:0,
      uang:0,
      diskons:false,
      skip_ambil_diskon:false,
      satuans:[],
      total_beli_pelanggan:0,
      membership:0,
      diskon_membership:0
    };
  },
  computed:{
    td(){
      let that=this
      let data= this.datanya2 
      data=data.filter(e=>{
        if(e.nama_produk.toLowerCase().indexOf(that.search.toLowerCase())!=-1||e.barcode.toLowerCase().indexOf(that.search.toLowerCase())!=-1){
          return e
        }
      })
      return data
    }
  },
  methods: {
    kurangjumlah(index,akhir){
      if(this.jumlah[index].jumlah>=1){
        this.jumlah[index].jumlah=this.jumlah[index].jumlah-1;
        this.$forceUpdate();
      }
    },
    tambahjumlah(index,akhir){
      if(this.jumlah[index].jumlah<=parseInt(akhir)-1){
        this.jumlah[index].jumlah=this.jumlah[index].jumlah+1;
        this.$forceUpdate();
      }
    },
    async tambahkeranjang(item,index){
      let that=this;
       this.datawhistlist2.status='keluar'
       this.datawhistlist2.id_produk=item.id
      this.datawhistlist2.tanggal_w=this.format(new Date(this.datawhistlist2.tanggal));
      this.datawhistlist2.id_user=this.$store.state.users.id
      this.datawhistlist2.posisi='keranjang'
      this.datawhistlist2.beli='kasir'
      this.datawhistlist2.jumlah=this.jumlah[index].jumlah;
      this.datawhistlist2.satuan=item.satuan
    
      if(this.datawhistlist2.id_pelanggan){
        if(this.datawhistlist2.jumlah>0){
          this.hitung_total(item);
          this.datawhistlist2.harga=item.harga
          let satuan=parseInt(this.satuans.filter(e=>e.id==item.satuan)[0].perkalian)
          if(this.diskonkan){
            this.datawhistlist2.diskon=parseInt(item.diskon)+parseInt(this.diskon_membership);
            this.datawhistlist2.total=(parseInt(item.harga)*satuan)*this.datawhistlist2.jumlah-((this.datawhistlist2.diskon/100)*parseInt(item.harga)*this.datawhistlist2.jumlah)
          }else{
            this.datawhistlist2.diskon=parseInt(item.diskon);
            this.datawhistlist2.total=(parseInt(item.harga)*satuan)*this.datawhistlist2.jumlah-((this.datawhistlist2.diskon/100)*parseInt(item.harga)*this.datawhistlist2.jumlah)
          }
          console.log(this.datawhistlist2)
              delete this.datawhistlist2.id;
              sdb
                .collection("app_kasir_keranjang",true)
                .doc()
                .set(this.datawhistlist2)
                .then((res) => {
                  that.getData();
                  that.cekstok();
                    for(let i=0;i<2000;i++){
                      that.jumlah[i]={
                        jumlah:0
                      }
                    }
                });
        }else{
          sdb.alert('Jumlah tidak boleh 0 !')
        }
      }else{
          sdb.alert('Silahkan pilih pelanggan terlebih dahulu !')
      }
    },
    async hapus(item){
      sdb.collection("app_kasir_keranjang").doc(item.id).delete().then(res => {
          console.log(res);
          this.getData();
      });
    },
     formatRupiah(angka, prefix){ // prefix disini taruh Rp. 
          angka=angka.toString();
          var number_string = angka.replace(/[^,\d]/g, '').toString(),
          split   		= number_string.split(','),
          sisa     		= split[0].length % 3,
          rupiah     		= split[0].substr(0, sisa),
          ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
          if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          }
          rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
          return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
      },
     getStar(item){
        let hasil;
        if(((5*parseInt(item.star5) + 4*parseInt(item.star4) + 3*parseInt(item.star3) + 2*parseInt(item.star2) + 1*parseInt(item.star1)) / (parseInt(item.star5)+parseInt(item.star4)+parseInt(item.star3)+parseInt(item.star2)+parseInt(item.star1))).toFixed(1)=='NaN'){
          hasil=0;
        }else{
          hasil=((5*parseInt(item.star5) + 4*parseInt(item.star4) + 3*parseInt(item.star3) + 2*parseInt(item.star2) + 1*parseInt(item.star1)) / (parseInt(item.star5)+parseInt(item.star4)+parseInt(item.star3)+parseInt(item.star2)+parseInt(item.star1))).toFixed(1)
        }
        return hasil
      },
      test(){
          alert('y')
      },
      async cekmembership(){
        let that=this;
        this.membership=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.datawhistlist2.id_pelanggan}`)
        console.log('membership',this.membership)
        this.membership=parseInt(this.membership[0].membership)
        let listmembership = await sdb.collection("app_kasir_pelanggan_membership",false).doc().select(`select * from app_kasir_pelanggan_membership`)
        let diskon
        this.total_beli_pelanggan=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`select * from app_kasir_transaksi_nota where id_pelanggan='${this.datawhistlist2.id_pelanggan}'`)
        this.total_beli_pelanggan=this.total_beli_pelanggan.reduce((e,n)=>{
          return e+(parseInt(n.total))
        },0)
        if(this.membership!=0){
          diskon=listmembership.filter(e=>{
            if(this.total_beli_pelanggan>parseInt(e.total_beli)){
              return e
            }
          })
          this.diskon_membership=parseInt(diskon[0].diskon)
        }
        this.$forceUpdate();        
        
      },
      tambahDiskon(){
        this.datawhistlist2.diskon=parseInt(this.datawhistlist2.diskon)+parseInt(this.diskon_membership)
        this.hitung_total();
        this.$forceUpdate();
      },
      async tambahPointPelanggan(){
        if(parseInt(this.getJam())<16){ // jam pagi
          let data={}
          data.id = this.datawhistlist2.id_pelanggan
          let pelanggan=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.datawhistlist2.id_pelanggan}`)
          data.point=parseInt(pelanggan[0].point)+25
          console.log('data pelanggan',data)
          sdb.collection('app_kasir_pelanggan').doc().set(data).then(res=>{
              console.log(res)
          })
        }else if(parseInt(this.getJam())>16){ // jam sore/malam
           let data={}
          data.id = this.datawhistlist2.id_pelanggan
          let pelanggan=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.datawhistlist2.id_pelanggan}`)
          data.point=parseInt(pelanggan[0].point)+35
          console.log('data pelanggan',data)
          sdb.collection('app_kasir_pelanggan').doc().set(data).then(res=>{
              console.log(res)
          })
        }
    
      },
      tambah(){
          this.datawhistlist2.jumlah=parseInt(this.datawhistlist2.jumlah)+1;
          this.hitung_total()
      },
      kurang(){
          this.datawhistlist2.jumlah=parseInt(this.datawhistlist2.jumlah)-1;
          this.hitung_total()
      },
      format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
      format2(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD-HH-mm");
      },
      getJam(tanggal) {
        return this.$datefns.format(new Date(),"HH");
      },
      getNota(){
          let code='KA';//KA = kasir, ON=Online, OF=OFFLINE
          sdb.collection("app_kasir_transaksi_nota").doc().get("*",`where id_user='${this.$store.state.users.id}' order by id`).then(res => {
              console.log('getnota',res);
              this.datawhistlist2.nota=code+this.format(new Date()).split('-').join('')+'-'+this.$store.state.users.id+'-'+res.length;
              this.$forceUpdate();
          });
      },
     async cekstok(item){
        console.log(this.datawhistlist2)
          let stok=await sdb.collection("app_kasir_produk").doc().get("*",`where id='${this.datawhistlist2.id_produk}' order by id`).then(res => {
         if(!this.skip_ambil_diskon){
             this.datawhistlist2.diskon=res[0].diskon
         }
          this.datawhistlist2.harga=res[0].harga
          this.datawhistlist2.satuan=res[0].satuan
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk").doc().get("*",`where id_produk='${this.datawhistlist2.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_stok_keluar").doc().get("*",`where id_produk='${this.datawhistlist2.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      let transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.datawhistlist2.id_produk}' order by tb1.id`).then(res => {
          console.log('res',res);
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
      stok=stok+(masuk-keluar-transaksi)
      this.stok=stok;
      this.hitung_total();
      this.$forceUpdate();
    },
    hitung_diskon(){
        let data= this.datanya;
        if(data.length>0){
            this.total=data.reduce((e,n)=>{
                let diskon = (parseInt(n.diskon)/100)*parseInt(n.harga)
                return e+((parseInt(n.harga)*parseInt(n.jumlah))-diskon)
            },0)
            this.diskons=true;
        }
        this.$forceUpdate();
    },
    hitung_total(item){
        let satuan=parseInt(this.satuans.filter(e=>e.id==item.satuan)[0].perkalian)
        this.datawhistlist2.total=(parseInt(item.harga)*satuan)*item.jumlah-((parseInt(item.diskon)/100)*parseInt(item.harga)*item.jumlah)
        this.$forceUpdate();
    },
    deleteAll(){
        if(confirm('apakah yakin menghapus semua data?')){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_keranjang WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                console.log(res);
                this.getData();
            });
        }
    },
    deleteAll2(){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_keranjang WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                console.log(res);
                this.getData();
            });
    },
    async getData(){
      let that=this;
        this.masuk=await sdb.collection("app_kasir_stok_masuk").doc().get("*","order by id").then(res => {
            return res
        });
        this.keluar=await sdb.collection("app_kasir_stok_keluar").doc().get("*","order by id").then(res => {
            return res
        });
        this.transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota order by tb1.id`).then(res => {
            return res
        });
        // this.transaksi=await sdb.collection("app_kasir_transaksi_nota").doc().get("*","tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota order by tb1.id").then(res => {
        //     return res
        // });
        sdb.collection("app_kasir_produk",false).doc().select(`select * from app_kasir_produk  where type='1' order by id desc`).then(res => {
          console.log('datanya2',res)
          let data =res;
          data=data.map(e=>{
            return {
              ...e,
              bintang:that.getStar(e),
              stok_akhir:parseInt(e.stok)+(that.masuk.reduce((k,n)=>{if(n.id_produk==e.id){  return k+parseInt(n.jumlah)}else{  return k}},0)-that.keluar.reduce((k,n)=>{if(n.id_produk==e.id){  return k+parseInt(n.jumlah)}else{  return k}},0)-that.transaksi.reduce((k,n)=>{if(n.id_produk==e.id){  return k+parseInt(n.jumlah)}else{  return k}},0)),
              after_diskon:parseInt(e.harga)-((parseInt(e.diskon)/100)*parseInt(e.harga))
            }
          })
          this.datanya2=data;
          this.$forceUpdate();
        });
      this.cekstok();
      sdb.collection("app_kasir_keranjang",false).doc().select(`
      select tb1.id,tb1.id_user,tb1.nota,tb1.tanggal,tb1.id_pelanggan,tb2.barcode,tb1.harga,tb1.jumlah,tb2.nama_produk,tb1.id_produk,tb1.diskon,tb1.total,tb1.tanggal_w,tb2.gambar1
      from app_kasir_keranjang tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk where tb1.id_user='${this.$store.state.users.id}' AND tb1.posisi='keranjang'`).then(res => {
        this.datanya=res;
        if(res.length>0){
            this.total=res.reduce((e,n)=>{
                return e+(parseInt(n.total))
            },0)
            let date = this.format(new Date()).split('-')[2]
            if(res[0].tanggal_w.split('-')[2]==date){
            }else{
                setTimeout(() => {
                    this.deleteAll2();
                }, 1000);
            }
            this.hitung_total();
        }
        this.$forceUpdate();
      });
      sdb.collection("app_kasir_produk").doc().get("*","where type='1' order by id").then(res => {
          this.produks=res;
          this.produks.map(function (x){ // taruh setelah produks di initial
                return x.item_data = x.barcode + ' / ' + x.nama_produk;
          });
      });
       sdb.collection("app_kasir_pelanggan").doc().get("*","order by id").then(res => {
          this.pelanggans=res;
      });
      
    },
    ambil(data) {
      this.datawhistlist2 = data;
      this.cekstok();
      this.skip_ambil_diskon=true;
      console.log(data)
      this.btn = "update";
      this.$forceUpdate();
    },
    async bayar(){
      let that=this;
      if(this.uang==0){
        alert('Uang tidak boleh 0')
      }else if(this.uang<this.total){
        alert('Uang tidak boleh kurang dari total harga')
      }
      else{
        if(confirm('Apakah yakin di proses ?')){
            this.datawhistlist2.total=this.total;
            this.datawhistlist2.uang=this.uang
            this.datawhistlist2.id_user=this.$store.state.users.id
            this.datawhistlist2.id_pelanggan=this.datanya[0].id_pelanggan
            this.datawhistlist2.tanggal_w=this.datanya[0].tanggal_w
            this.datawhistlist2.cabang=this.$store.state.data.toko.cabang
            delete this.datawhistlist2.id_produk
            delete this.datawhistlist2.status
            delete this.datawhistlist2.barcode
            delete this.datawhistlist2.nama_produk
            delete this.datawhistlist2.harga
            delete this.datawhistlist2.satuan
            delete this.datawhistlist2.jumlah
            delete this.datawhistlist2.diskon
            let data = this.datanya;
            data=data.map(e=>{
                return e.id
            })
            delete this.datawhistlist2.id_produk
            delete this.datawhistlist2.status
            this.datawhistlist2.status='3'
            this.datawhistlist2.list_id=data.toString()
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            console.log('bayar',this.datawhistlist2)
            await sdb.collection('app_kasir_transaksi_nota').doc().set(that.datawhistlist2)
            await sdb.collection("app_kasir_keranjang",false).doc().select(`update app_kasir_keranjang set posisi='nota' WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`)
            that.tambahPointPelanggan()
            setTimeout(() => {
              that.$router.push(`/admin/report/transaksi_now?nota=${that.datawhistlist2.nota}`)
            }, 3000);
        }
      }
    },
   async submit() {
      let that=this
      this.datawhistlist2.status='keluar'
      this.datawhistlist2.tanggal_w=this.format(new Date(this.datawhistlist2.tanggal));
      this.datawhistlist2.id_user=this.$store.state.users.id
      this.datawhistlist2.posisi='keranjang'
      this.datawhistlist2.beli='kasir'
      this.datawhistlist2.cabang=this.$store.state.data.toko.cabang
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di datawhistlist2
          delete this.datawhistlist2.id;
          await sdb
            .collection("app_kasir_keranjang",false)
            .doc()
            .set(this.datawhistlist2)
              that.getData();
              that.cekstok();
              
            // that.$nuxt.$emit('getData')

        } else if (this.btn == "update") {
         await sdb
            .collection("app_kasir_keranjang",false)
            .doc()
            .set(this.datawhistlist2)
              that.getData();
              that.cekstok();
            // that.$nuxt.$emit('getData')
        } else if (this.btn == "delete") {
         await sdb
            .collection("app_kasir_keranjang",false)
            .doc(this.datawhistlist2.id)
            .delete()
              that.getData();
              that.cekstok();
            // that.$nuxt.$emit('getData')
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
    getTanggal(){
        this.datawhistlist2.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")
    },
    getSatuan(){
      sdb.collection("app_kasir_produk_satuan").doc().get("*","order by id").then(res => {
          this.satuans=res;
          this.$forceUpdate();
      });
      
    }
  },
  async mounted() {
    for(let i=0;i<2000;i++){
      this.jumlah[i]={
        jumlah:0
      }
    }
      setTimeout(() => {
        this.getNota();
        this.getData();
        this.getSatuan();
    }, 1000);
    this.getTanggal();
  },
};
</script>